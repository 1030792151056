//查询事件列表
const getOrderInfoListUrl = `/cyproxy/order-info/getOrderInfoList`;
//分页事件
const getOrderInfoPageListUrl = `/cyproxy/order-info/getOrderInfoPageList`;
//查询事件件数
const getOrderInfoCountUrl = `/cyproxy/order-info/getOrderInfoCount`;
// 更新 - 事件 - 状态
const updateOrderWorkStatusUrl = `/cyproxy/order-info/updateOrderStatus`;
export {
  getOrderInfoListUrl,
  getOrderInfoPageListUrl,
  getOrderInfoCountUrl,
  updateOrderWorkStatusUrl,
};
